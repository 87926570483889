<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="compass"></a-icon>
          <span>站点</span>
        </a-space>
      </template>
      <span>站点包含站点名称、URL、监控频率、状态等。</span>
    </a-page-header>

    <a-card :bordered="false">
      <site-table
        :refresh="refresh"
        :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
      ></site-table>
    </a-card>
  </div>
</template>

<script>
import SiteTable from '@/components/table/SiteTable/index.vue'

export default {
  name: 'Site',
  components: {
    SiteTable
  },
  data () {
    return {
      refresh: false
    }
  }
}
</script>
